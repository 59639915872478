import React from 'react'
import './header-nav.scss'
import { Link } from 'gatsby'
import MobileIcon from '../../images/phone-red.svg'

const HeaderNav = () => {
    return (
        <nav>
            {/* <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to="/short-term-properties/" className="header-links">Short Stay</Link>
                </div>
            </div> */}
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to="/properties/" className="header-links">Properties</Link>
                </div>
                <ul className="nav-dropdown-container">
                    <li>
                        <Link to="/properties/" className="header-links">Our Listings</Link>
                    </li>
                    <li>
                        <Link to="/commercial-properties/" className="header-links">Commercial Properties</Link>
                    </li>
                    <li>
                        <Link to="/properties/in-the-pearl-island---doha/" className='header-links'>The Pearl Properties</Link>
                    </li>
                    <li>
                        <Link to='/recent-sales/' className="header-links">Recent Sales</Link>
                    </li>
                    <li>
                        <Link to="/new-developments/" className="header-links">New Developments</Link>
                    </li>
                    <li>
                        <Link to="/international-properties/" className="header-links">International Properties</Link>
                    </li>
                </ul>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to='/buy-with-us/' className="header-links">Buy</Link>
                </div>
                <ul className="nav-dropdown-container">
                    <li>
                        <Link to='/properties/type-buy/category-apartment/' className="header-links">Apartments for Sale in Qatar</Link>
                    </li>
                    <li>
                        <Link to='/properties/type-buy/in-the-pearl-island---doha/category-apartment/' className="header-links">Apartments for Sale in The Pearl</Link>
                    </li>
                    <li>
                        <Link to='/properties/type-buy/in-lusail-city---lusail/category-apartment/' className="header-links">Apartments for Sale in Lusail</Link>
                    </li>
                    <li>
                        <Link to='/buy-with-us/' className="header-links">Buy with Us</Link>
                    </li>
                    <li>
                        <Link to="/qatar-real-estate-investments/" className="header-links">Foreign Investors</Link>
                    </li>
                    <li>
                        <Link to="/doha-neighborhoods-guide/" className="header-links">Doha Neighborhood Guide</Link>
                    </li> 
                </ul>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to='/properties/type-rent/' className="header-links">Rent</Link>
                </div>
                <ul className="nav-dropdown-container">
                    <li>
                        <Link to='/properties/type-rent/category-apartment/' className="header-links">Apartments for Rent in Qatar</Link>
                    </li>
                    <li>
                        <Link to="/properties/type-rent/in-the-pearl-island---doha/category-apartment/" className="header-links">Apartments for Rent in The Pearl</Link>
                    </li>
                    <li>
                        <Link to="/properties/type-rent/in-lusail-city---lusail/category-apartment/" className="header-links">Apartments for Rent in Lusail</Link>
                    </li> 
                </ul>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to='/list-with-us/' className="header-links">Sellers</Link>
                </div>
                <ul className="nav-dropdown-container">
                    <li>
                        <Link to='/list-with-us/' className="header-links">List with us</Link>
                    </li>
                    <li>
                        <Link to='/marketing/' className="header-links">Marketing</Link>
                    </li>
                </ul>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to="/25-spaces-real-estate/" className="header-links">About Us</Link>
                </div>
                <ul className="nav-dropdown-container">
                    <li>
                        <Link to="/25-spaces-real-estate/" className="header-links">25 Spaces Real Estate</Link>
                    </li>
                    <li>
                        <Link to="/our-office/" className="header-links">Our Office</Link>
                    </li>
                    <li>
                        <Link to="/what-our-clients-are-saying/" className="header-links">What Our Clients Are Saying</Link>
                    </li> 
                    <li>
                        <Link to="/careers/" className="header-links">Careers</Link>
                    </li>
                </ul>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to="/our-services/" className="header-links">Our Services</Link>
                </div>
                <ul className="nav-dropdown-container">
                    <li>
                        <Link to="/our-services/property-management/" className="header-links">PROPERTY MANAGEMENT</Link>
                    </li>
                    <li>
                        <Link to="/our-services/property-consultancy-advice/" className="header-links">PROPERTY CONSULTANCY ADVICE</Link>
                    </li>
                    <li>
                        <Link to="/our-services/real-estate-valuation/" className="header-links">REAL ESTATE VALUATION</Link>
                    </li>
                    <li>
                        <Link to="/our-services/professional-virtual-tours/" className="header-links">PROFESSIONAL VIRTUAL TOURS</Link>
                    </li>
                    <li>
                        <Link to="/our-services/professional-photography/" className="header-links">PROFESSIONAL PHOTOGRAPHY</Link>
                    </li>
                    <li>
                        <Link to="/our-services/snagging-and-handover/" className="header-links">SNAGGING AND PRE-HANDOVER CHECKS</Link>
                    </li>
                    <li>
                        <Link to="/our-services/real-estate-and-interior-design/" className="header-links">REAL ESTATE AND INTERIOR DESIGN</Link>
                    </li>
                </ul>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to="/blogs/" className="header-links">Blogs</Link>
                </div>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <Link to="/contact-us/" className="header-links">Contact Us</Link>
                </div>
            </div>
            <div className="nav-heading-main-container">
                <div className="nav-heading-link-container">
                    <img src={MobileIcon} alt='mobile-img' /><a href='tel: +974 44642525' className="header-links">&nbsp;+974 4464 2525</a>
                </div>
            </div>
        </nav>
    )
}

export default HeaderNav