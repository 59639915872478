import React from 'react'
import './footer.scss';

import FooterSubscribe from '../FooterSubscribe/footer-subscribe.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import FooterSocialMediaIcons from '../FooterSocialMediaIcons/footer-social-media-icons';
import WhatsAppSticky from '../WhatsAppSticky/WhatsAppSticky';
import TelegramSticky from '../TelegramSticky/TelegramSticky';
import PopularSearch from './popularSearch';
import FooterSticky from '../FooterSticky/FooterSticky';
import ReviewImg from "../../images/5-star-google-review-logo.svg";


const Footer = (props) => {


    return (
        <StaticQuery
            query={graphql`
                query {
                    siteSettings: allContentfulSiteGeneralSettings(
                        filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
                    ) {
                        edges {
                            node {
                                crNumber
                                realEstateLicenseNumber
                            }
                        }
                    }
                    whatsapp: allContentfulUrlAddresses (filter: {linkName: {eq: "whatsapp"}}) {
                        edges {
                        node {
                            linkName
                            linkUrlAddress
                        }
                        }
                    }
                }
            `}
            render={data => (
                <> 
                 <PopularSearch></PopularSearch>
                <footer>  
                    <div className="menu-container">
                        <div className="menu-list-container">
                            <Link to="/properties/" className="header-links">Properties</Link>
                            <ul>
                                <li>
                                    <Link to="/properties/">Our Listings</Link>
                                </li>
                                <li>
                                    <Link to="/commercial-properties/">Commercial Properties</Link>
                                </li>
                                <li>
                                    <Link to="/properties/in-the-pearl-island---doha/">The Pearl Properties</Link>
                                </li>
                                <li>
                                    <Link to='/recent-sales/'>Recent Sales</Link>
                                </li>
                                <li>
                                    <Link to="/new-developments/">New Developments</Link>
                                </li>
                                <li>
                                    <Link to="/international-properties/">International Properties</Link>
                                </li>
                                {/* <li>
                                    <Link to="/short-term-properties/">Short Stay</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to='/buy-with-us/' className="header-links">Buy</Link>
                            <ul> 
                                <li>
                                    <Link to='/buy-with-us/'>Buy with Us</Link>
                                </li>
                                <li>
                                    <Link to="/qatar-real-estate-investments/">Foreign Investors</Link>
                                </li>
                                <li>
                                    <Link to="/doha-neighborhoods-guide/">Doha Neighborhood Guide</Link>
                                </li> 
                            </ul>
                        </div> 
                        <div className="menu-list-container">
                            <Link to='/list-with-us/' className="header-links">Sellers</Link>
                            <ul>
                                <li>
                                    <Link to='/list-with-us/'>List with us</Link>
                                </li>
                                <li>
                                    <Link to='/marketing/'>Marketing</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-list-container">
                            <Link to="/25-spaces-real-estate/" className="header-links">About Us</Link>
                            <ul>
                                <li>
                                    <Link to="/25-spaces-real-estate/">25 Spaces Real Estate</Link>
                                </li>
                                <li>
                                    <Link to="/our-office/">Our Office</Link>
                                </li>
                                <li>
                                    <Link to="/blog/">Blogs</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us/">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/what-our-clients-are-saying/">What Our Clients Are Saying</Link>
                                </li> 
                                <li>
                                    <Link to="/our-services/">Our Services</Link>
                                </li>
                                <li>
                                    <Link to="/careers/">Careers</Link>
                                </li>
                                
                            </ul>
                        </div> 
                    </div>

                    <div className="contact-info-footer-container">
                        <div className="logo-container">
                            <Link to='/'><img
                                src={props.logo}
                                alt="25 Spaces real estate"
                                width={150}
                                height={60}
                            /></Link>
                            <FooterSocialMediaIcons transparentBackground />
                            <TelegramSticky />
                            <WhatsAppSticky />
                            <Link className='google-review-logo' to="https://www.google.com/search?q=25+spaces+real+estate&rlz=1C5CHFA_enFR1015FR1015&oq=25+spaces+&aqs=chrome.0.0i355i512j46i175i199i512j69i57j0i512j0i10i22i30j69i60l2j69i61.8433j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3e45c4b871e3d2fd:0xf54afa7d6fa0189,1,,," target='_blank'>
                                <img
                                    src={ReviewImg}
                                    alt="review us on Google"
                                    width={150}
                                    height={60}
                                />
                            </Link>
                        </div>
                        <div className="address-container">
                            <p>The Pearl - Qatar, Medina Centrale
                                <br />Al Hambra St. Building No. 8, Unit No. 64
                                <br />PO Box 14050
                                <br />Doha, Qatar</p>
                            <p>{`CR Number - ${data.siteSettings.edges[0].node.crNumber}`}</p>
                            {data.siteSettings.edges[0].node.realEstateLicenseNumber
                                ? <p>{`Real Estate License No: ${data.siteSettings.edges[0].node.realEstateLicenseNumber}`}</p>
                                : ""
                            }

                            <div className='footer-contact'>
                                <p>Call us:</p>
                                <a href="tel:+97444642525">+974 4464 2525</a>
                                <a href="tel:+97433118588">+974 3311 8588</a>
                            </div>

                            <div className='whatsapp-footer'>
                                <a rel="noreferrer" aria-label="whatsapp" href={data.whatsapp.edges[0].node.linkUrlAddress} target="_blank"><FontAwesomeIcon className="font-awesome--footer" icon={faWhatsapp} /><span>Chat with us</span></a>
                            </div>
                        </div>
                        <FooterSubscribe></FooterSubscribe>

                    </div>

                    <StaticImage className="footer-bkg" src="../../images/footer-bkg.jpg" alt="Footer" />
                    <FooterSticky page={props.page}></FooterSticky>
                </footer>
                </>
            )}
        />
    )
}

export default Footer